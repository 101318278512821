// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-contact-page-js": () => import("/opt/build/repo/src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-sponsorship-page-js": () => import("/opt/build/repo/src/templates/sponsorship-page.js" /* webpackChunkName: "component---src-templates-sponsorship-page-js" */),
  "component---src-templates-walk-js": () => import("/opt/build/repo/src/templates/walk.js" /* webpackChunkName: "component---src-templates-walk-js" */),
  "component---src-templates-newsletter-js": () => import("/opt/build/repo/src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-board-js": () => import("/opt/build/repo/src/templates/board.js" /* webpackChunkName: "component---src-templates-board-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-405-js": () => import("/opt/build/repo/src/pages/405.js" /* webpackChunkName: "component---src-pages-405-js" */),
  "component---src-pages-admin-actions-js": () => import("/opt/build/repo/src/pages/admin/actions.js" /* webpackChunkName: "component---src-pages-admin-actions-js" */),
  "component---src-pages-applications-js": () => import("/opt/build/repo/src/pages/applications.js" /* webpackChunkName: "component---src-pages-applications-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/build/repo/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-news-archive-js": () => import("/opt/build/repo/src/pages/news/archive.js" /* webpackChunkName: "component---src-pages-news-archive-js" */),
  "component---src-pages-news-index-js": () => import("/opt/build/repo/src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-pilgrim-lists-index-js": () => import("/opt/build/repo/src/pages/pilgrim-lists/index.js" /* webpackChunkName: "component---src-pages-pilgrim-lists-index-js" */)
}

